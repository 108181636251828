import { Controller } from "@hotwired/stimulus"

import * as ace from 'brace';
import 'brace/mode/liquid';
import 'brace/mode/html';
import 'brace/theme/solarized_light';
// import format from "html-format";

export default class extends Controller {
  static targets = [ "input", "editor" ]

  initialize() {
  }
  connect() {

  }
  // minify = ( s ) => {
  //   return s ? s
  //     .replace(/\>[\r\n ]+\</g, "><")  // Removes new lines and irrelevant spaces which might affect layout, and are better gone
  //     .replace(/(<.*?>)|\s+/g, (m, $1) => $1 ? $1 : ' ')
  //     .trim()
  //     : ""
  // }

  minifyHtml = (html) => {
    // Remove line breaks and indentation
    return html
      .replace(/[\n\r\t]/g, '') // Remove newlines, carriage returns and tabs
      .replace(/\s+/g, ' ') // Replace multiple spaces with single space
      .replace(/>\s+</g, '><') // Remove spaces between tags
      .replace(/\s+>/g, '>') // Remove spaces before closing angle bracket
      .replace(/<\s+/g, '<') // Remove spaces after opening angle bracket
      .trim(); // Remove leading/trailing whitespace
  }

  prettifyHtml = (html) => {
    let indent = 0,
        mode = 'IDLE',
        inTag = false,
        tag = '',
        tagToCome = '',
        shouldBreakBefore = false,
        shouldBreakAfter = false,
        breakBefore = ['p', 'ul', 'li'],
        breakAfter = ['div', 'h1', 'h2', 'h3', 'h4', 'p', 'ul', 'li'];

    return html
        .split('')
        .reduce((output, char, index) => {

            if (char === '<') {
                tagToCome = this.whichTag(html, index);
                shouldBreakBefore = tagToCome && breakBefore.indexOf(tagToCome) >= 0;
                mode = 'TAG';
                inTag = true;
                output += (shouldBreakBefore ? this.br(indent) : '') + '<';
            } else if (char === '/' && mode == 'TAG') {
                mode = 'CLOSING_TAG'
                inTag = true;
                output += '/';
            } else if (char === ' ') {
                inTag = false;
                output += ' ';
            } else if (char === '>') {
                if (mode === 'TAG' || mode === 'CLOSING_TAG') {
                    indent += mode === 'TAG' ? +1 : -1;

                    shouldBreakAfter = breakAfter.indexOf(tag) >= 0;
                    inTag = false;
                    tag = '';
                }
                output += '>';
                output += shouldBreakAfter ? this.br(indent) : '';
            } else {
                output += char;

                if (inTag) {
                    tag += char;
                }
            }

            return output;
        }, '');
  }
  _tabs = (number) => {
    let output = '';

    for (let cnt = 0; cnt < number; cnt++) {
        output += '\t';
    }

    return output;
}

br = (indent) => {
    return '\n' + this._tabs(indent);
}

whichTag = (html, index) => {
    let inTag = true,
        tag = '';

    const arr = html.split('');

    for (let i = index + 1; i < index + 10; i++) {
        const char = arr[i];

        if (char >= 'a' && char <= 'z' && inTag) {
            tag += char;
        } else if (char !== '/') {
            inTag = false;
        }
    }

    return tag;
}

  connect() {
    console.log(this.editorTargets);
    // load();
  }

  load() {
    this.editorTargets.forEach((edTarget, idx) => {
      let editor = ace.edit(edTarget);
      editor.setBehavioursEnabled(true)
      editor.getSession().setMode('ace/mode/html');
      editor.getSession().setUseWrapMode(true);
      editor.getSession().setFoldStyle("markbegin")
      editor.setTheme('ace/theme/github_light');
      let val = this.prettifyHtml(this.inputTargets[idx].value);
      // let val = this.inputTargets[idx].value;
      // val = val.replace(/\\/g, '');
      // console.log("HTML val");
      // console.log(val);
      editor.setValue(val);

    });
  }

  submit() {
    this.editorTargets.forEach((edTarget, idx) => {
      let editor = ace.edit(edTarget);
      let val = editor.getValue();
      console.log(`VALUE LENGTH - ${val.length}`)
      let minified = this.minifyHtml(val);
      console.log(`MINIFIED LENGTH - ${minified.length}`)
      console.log(minified)
      
      this.inputTargets[idx].value = unescape(minified);
    });
  }
  
}