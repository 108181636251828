import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["selectorType", "selectorInput", "errorMessage"];

  remove(e) {
    this.element.remove();
  }

  validate(e) {
    const selectorType = this.selectorTypeTarget.value;
    const selectorValue = this.selectorInputTarget.value;

    if(selectorValue?.length <= 0 || this.isValid(selectorType, selectorValue)) {
      this.selectorInputTarget.classList.remove('border-red-500', 'ring-red-500', 'focus:border-red-500', 'focus:ring-red-500');
      this.errorMessageTarget.classList.add("hidden")
      this.selectorInputTarget.setCustomValidity("")

    } else {
      this.selectorInputTarget.classList.add('border-red-500', 'ring-red-500', 'focus:border-red-500', 'focus:ring-red-500');
      this.errorMessageTarget.classList.remove("hidden")
      this.selectorInputTarget.setCustomValidity("Not a valid selector.")

    }

    return false;
  }

  isValid(selectorType, selectorValue) {
    if (selectorType === 'css') {
      try {
        document.querySelector(selectorValue);
        return true;
      } catch (e) {
        return false;
      }
    } else if (selectorType === 'xpath') {
      try {
        document.evaluate(selectorValue, document, null, XPathResult.ANY_TYPE, null);
        return true;
      } catch (e) {
        return false;
      }
    }
  }
}